window.esign = window.esign || {};


esign.init = function () {
  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [1024,0],
    lazy: true
  });

  esign.ageCheck();

  // esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.responsiveVideos();
  esign.asideScrollable();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  if(esign.detectIE()) {
    $('body').addClass('flex-edge-ie-fix');
  }

  $('.scroll-to').on('click', function () {
    var target = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(target).offset().top
    }, 2000);
  });
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $nav: $('.main-nav-wrap')
  };
};

esign.navigation = function () {
  $('.main-nav-trigger').click(function(e) {
    e.preventDefault();
    $(this).next('.main-nav-wrap').slideToggle('fast');
  });

  $('.main-nav__dropdown-toggle').each(function() {
    var $this = $(this);
    var $dropdown = $this.next();
    var $dropdownIcon = $this.find('.icon');
    var $dropdownText = $this.find('.text');
    var open = false;

    var moreIcon = $this.data('more');
    var moreText = $this.data('more-text');
    var lessIcon = $this.data('less');
    var lessText = $this.data('less-text');

    $this.click(function(e) {
      e.preventDefault();

      if (!open) {
        open = true;
        $dropdown.show('fast');
        $dropdownIcon.text(lessIcon);
        $dropdownText.text(lessText);
      } else {
        open = false;
        $dropdown.hide('fast');
        $dropdownIcon.text(moreIcon);
        $dropdownText.text(moreText);
      }

      $this.attr('area-expanded', open.toString());
    });
  });

  Response.crossover('width', function() {
    if(Response.band(1024)) {
      esign.cache.$nav.css('display', 'block');
    } else {
      esign.cache.$nav.css('display', 'none');
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};

esign.asideScrollable = function () {
  var isChanging = false;

  $('.page-aside--scrollable').waypoint(function(direction) {
    var $this = $(this.element);
    var bottomClass = 'page-aside--scrollable-bottom';
    if(!isChanging) {
      isChanging = true;
      if (direction === 'down') {
        $this.addClass(bottomClass, function () {
          isChanging = false;

        });
      } else {
        $this.removeClass(bottomClass, function () {
          isChanging = false;

        });
      }
    }


	}, { offset: 'bottom-in-view' });
};

esign.articlesList = function(Masonry, imagesLoaded) {
  var container = document.querySelector('.articles-list');

  if (container) {
    var msnry = new Masonry('.articles-list', {
      columnWidth: '.grid__item',
      itemSelector: '.grid__item',
      percentPosition: true
    });

    var imgLoad = imagesLoaded(container);
    imgLoad.on('always', function() {
      msnry.layout();
    });
  }
}

esign.ageCheck = function () {
  var cookieNamespace = 'corsendonk-age-check';
  var $ageCheck = $('#age-check');

  if(!esign.readCookie(cookieNamespace)) {
    $ageCheck.show();

    $('.age-check__no').click(function(e) {
      $('.age-check__container').html('<div class="age-check__message">Sorry, you have to be older than 18 years to visit our website.</div>')
    });

    $('.age-check__yes').click(function(e) {
      $ageCheck.remove();
      esign.createCookie(cookieNamespace, 'popup', 365)
    });
  } else {
    $ageCheck.remove();
  }
};

esign.createCookie = function (name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  }
  else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
};

esign.readCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') == false) {
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() != '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');
  form_data = $form.serializeArray();

  $.post($form.attr('action'), form_data, function (data) {

    $('li').removeClass('error');
    var $result = $form.find('.result');

    if (data.errors === false) {
      $form.html(data.result);

      if (typeof ga === "function") {
        ga('send', 'pageview', $form.attr('action'));
      }

      //todo GTM trigger event

    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr('disabled');

  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if(esign.cache.isMobile) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }
};


// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
      isMobile = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/)  ||
        deviceAgent.match(/(iemobile)/) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i)) ||
        (/OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent));

  if(isMobile) {
    $('html').addClass('mobile');
  } else {
    $('html').addClass('no-mobile');
  }

  return isMobile;
};

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
esign.detectIE = function () {
  var ua = window.navigator.userAgent;
  var is_ie = false;
  var is_ie_or_edge = false;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    is_ie = true;
    is_ie_or_edge = true;
    //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    is_ie = true;
    is_ie_or_edge = true;
    // var rv = ua.indexOf('rv:');
    // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    is_ie = false;
    is_ie_or_edge = true;
    //return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  //return false;
  //return is_ie_or_edge;
  return is_ie_or_edge;
}

// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/validation/languages/jquery.validationEngine-nl',
  'js/libs/validation/jquery.validationEngine',
  'js/googlemaps-styles',
  'js/social',
  'waypoints/lib/jquery.waypoints.min.js',
], function() {
  // Initialize on docready
  $(esign.init);
});

require([
  'masonry-layout/masonry.js',
  'imagesloaded/imagesloaded.js'
], function(Masonry, imagesLoaded) {
  esign.articlesList(Masonry, imagesLoaded);
});

define("../resources/assets/js/esign", function(){});

